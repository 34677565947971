export function clearObject(obj: Record<string, unknown>) {

  for (const key of Object.keys(obj)) {
    obj[key] = undefined;
  }

}

export const waitFor = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const statusMap = {
  "PUBLISHED": "Julkinen",
  "PRIVATE": "Piilotettu",
};

export function formatContentStatus(val: string) {
  return (statusMap as any)[val];
};

export type DeferredPromise<T> = {
  resolve: (value: T) => void;
  reject: (reason?: any) => void;
  promise: Promise<T>;
}

export function deferred<T>() {
  let def: any = {};

  def.promise = new Promise<T>((resolve, reject) => {
    def.reject = reject;
    def.resolve = resolve;
  });

  return def as DeferredPromise<T>;
}

export function debounce(ms: number, fn: (...args: any[]) => void) {
  let timeout: any = null;
  return (...args: any[]) => {
    const call = () => fn(...args);
    clearTimeout(timeout);
    timeout = setTimeout(call, ms);
  };
};
